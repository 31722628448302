/* Open tabs with tab id in url */
import '../../shared/confirm';

let url = location.href.replace(/\/$/, "");
if (location.hash) {
    const hash = url.split("#");
    let linktab = $('#linkTab a[href="#'+hash[1]+'"]');
    if (linktab.length > 0)
    {
        linktab.tab("show");
        url = location.href.replace(/\/#/, "#");
        history.replaceState(null, null, url);
        setTimeout(() => {
            $(window).scrollTop(0);
        }, 0);
    }
}
$('a[data-toggle="tab"]').on("click", function() {
    let newUrl;
    const hash = $(this).attr("href");
    if(hash === "#") {
        newUrl = url.split("#")[0];
    } else {
        newUrl = url.split("#")[0] + hash;
    }
    newUrl += "/";
    history.replaceState(null, null, newUrl);
});

$('form.preloader').on('submit', function () {showPreloader()});
$('a.preloader').on('click', function () {showPreloader()});
function showPreloader() {
    $('#preloader').fadeIn(300);
}

$('.btn-toggle').on('click', function ()
{
    if ($($(this).attr('href')).hasClass('show')) {
        $(this).html($(this).data('text-more'));
    } else {
        $(this).html($(this).data('text-less'));
    }
})

$(function () {
    $('[data-toggle="popover"]').popover()
})

$(function () {
    $('[data-toggle="popover-gold"]').popover({
        template: '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header gold"></h3><div class="popover-body"></div></div>',
    })
})


$(document).on('click', '.clickable-link', function() {
    let target;
    if ($(this).data('target') === undefined) {
        target = '_self';
    } else {
        target = $(this).data('target');
    }
    window.open($(this).data("href"), target);
});

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
})

window.selectBoxDependency = function (secondSelectBox, dataUrl) {
    let secondSelectBoxValue = secondSelectBox.val();
    $.ajax({
        url: dataUrl,
        success: function (result) {
            secondSelectBox.empty();
            if (Object.keys(result).length > 0) {
                $.each(result, function (name, value) {
                    let option = new Option(name, value);
                    $(option).html(name);
                    secondSelectBox.append(option);
                    if (secondSelectBoxValue) {
                        secondSelectBox.val(secondSelectBoxValue);
                    }
                });
            }
        }
    });
}

function fallbackCopyTextToClipboard()  {
    document.execCommand('copy');
}
function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text);
}
$(document).on('click', '.copyText', function () {
    copyTextToClipboard($(this).data('text'));
})

window.initDatePicker = function()
{
    $('.select2modal').select2();
    $('.js-datepicker').datepicker({
        format: 'dd.mm.yyyy',
        weekStart: 1,
        todayHighlight: true,
    });
    $('.js-datepicker-ddmm').datepicker({
        format: 'dd.mm',
        weekStart: 1,
        todayHighlight: true,
    });
    $('.js-datepicker-birthday').datepicker({
        format: 'dd.mm.yyyy',
        startView: 2,
        weekStart: 1,
        todayHighlight: true,
        defaultViewDate: {year: '1980'}
    });
/*
    let dateRange = $('input[name="dateRange"]');
    dateRange.daterangepicker({
        autoUpdateInput: false,
        singleDatePicker: true,
        locale: {
            cancelLabel: 'Clear'
        }
    });
    dateRange.on('apply.daterangepicker', function (ev, picker) {
        $(this).val(picker.startDate.format('MM/DD/YYYY'));
    });
    dateRange.on('cancel.daterangepicker', function () {
        $(this).val('');
    });
*/
    $('[data-toggle="popover"]').popover();
}
$(function() {
    initDatePicker();
})
$(document).on('select2:open', () => {
    let select2Dropdown = document.querySelector('.select2-search--dropdown .select2-search__field');
    if ($(select2Dropdown).length > 0)
    {
        select2Dropdown.focus();
    }
});

$(document).on('click', '.text-changer', function() {
    let changeElements = $(this).data('text-changer-class');
    if (changeElements) {
        $('.'+changeElements).each(function () {
            changeText($(this));
        });
    } else {
        changeText($(this));
    }
    function changeText(element) {
        let text1 = element.data('text1');
        let text2 = element.data('text2');
        element.text(function(){
            return $(this).text() === text2 ?  text1 : text2;
        });
    }
});

$('.read-all-btn').on('click', function () {
    $('.read-more a').click();
})

$(document).on('click', '.modal-anker', function () {
    $(this).closest('.modal').modal('hide');
})

$('.custom-file-label').html('Datei auswählen ...');
$(document).on('change','input[type="file"]', function(e) {
    let fileName
    if (e.target.files.length === 1) {
        fileName = e.target.files[0].name;
    } else {
        fileName = e.target.files.length +  ' Dateien';
    }
    $(e.target).siblings('.custom-file-label').html(fileName);
});

$(document).ready(function() {
    let holidaySelect = $('.select-holidays');
    let select = holidaySelect.find('.selectric-items');
    select.append(holidaySelect.data('holidays'));
});

$(document).on('click', '.clickable-row:not(.checkbox-tr)', function(e) {
    let clicked = $(e.target);
    let clickedParent = clicked.parent();
    if (
        !clicked.hasClass('clickable-row-ignore')
        && !clickedParent.hasClass('clickable-row-ignore')
        && $(e.target).attr('href') === undefined
    ) {
        window.open($(this).data("href"), $(this).data("target")).focus();
    }
});
