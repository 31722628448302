import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {

    static values = {
        'refreshContentUrl' : String,
        'refreshContentId' : String,
        'modalIds' : Array,
    }

    async connect() {
        let content = $('#' + this.refreshContentIdValue);
        let url = this.refreshContentUrlValue
        $(this.modalIdsValue).each(function () {
            $('#charterAnfrageModal').on('hidden.bs.modal', function () {
                $.ajax({
                    type: 'GET',
                    url: url,
                    contentType:false,
                    processData:false,
                    cache:false,
                    success: function success(result) {
                        $(content).html(result);
                    },
                });
            })
        })
    }
}